//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { format, fromUnixTime } from 'date-fns';
import { PrescriptionService } from '@/services/prescription.service';
import { DoctorService } from '@/services/doctor.service';
export default {
  props: {
    prescriptionData: {
      type: Array,

      default() {
        return [];
      }

    }
  },

  data() {
    return {
      doctors: [],
      showDialog: false,
      selectedPrescription: {},
      prescriptionLogs: [],
      loadedPrescriptions: true,
      alert: {
        show: false,
        message: ''
      },
      loading: false,
      logFields: [{
        key: 'timestampcreated',
        label: 'Timestamp'
      }, {
        key: 'eventtype',
        label: 'Action'
      }, {
        key: 'user',
        label: 'User'
      }],
      statusMapper: {
        DRAFTED: {
          text: 'Draft',
          controls: {
            play: true,
            pause: false,
            stop: true
          }
        },
        PROCESSING: {
          text: 'Processing',
          controls: {
            play: false,
            pause: false,
            stop: false
          }
        },
        PRESCRIBED: {
          text: 'Prescribed',
          controls: {
            play: false,
            pause: true,
            stop: true
          }
        },
        ORDERED: {
          text: 'Ordered',
          controls: {
            play: false,
            pause: true,
            stop: true
          }
        },
        SCHEDULED: {
          text: 'Scheduled',
          controls: {
            play: false,
            pause: false,
            stop: true
          }
        },
        FAILED: {
          text: 'Failed',
          controls: {
            play: true,
            pause: false,
            stop: true
          }
        },
        PAUSED: {
          text: 'Paused',
          controls: {
            play: true,
            pause: false,
            stop: true
          }
        },
        COMPLETED: {
          text: 'Completed',
          controls: {
            play: true,
            pause: false,
            stop: true
          }
        },
        WAITING: {
          text: 'Waiting',
          controls: {
            play: false,
            pause: false,
            stop: true
          }
        },
        CANCELLED: {
          text: 'Cancelled',
          controls: {
            play: false,
            pause: false,
            stop: false
          }
        },
        REFILLED: {
          text: 'Refilled',
          controls: {
            play: false,
            pause: false,
            stop: true
          }
        }
      }
    };
  },

  watch: {
    async showDialog(show) {
      if (show) {
        const {
          data
        } = await DoctorService.getAllDoctors();
        this.doctors = data;

        if (!this.selectedPrescription) {
          this.selectedPrescription = this.prescriptionData[0];
        }
      } else {
        this.selectedPrescription = {};
      }
    },

    async selectedPrescription() {
      this.loading = true;
      this.alert.show = false;
      this.prescriptionLogs = [];
      const {
        data
      } = await PrescriptionService.getPrescriptionHistory(this.selectedPrescription.id);
      this.prescriptionLogs = data.sort((a, b) => {
        return a.timestampcreated - b.timestampcreated;
      });
      const stripeCancellation = this.prescriptionLogs.find(o => o.eventtype == 'Prescription Cancelled' && o.cancelledby == 'Stripe');

      if (stripeCancellation) {
        this.prescriptionLogs = this.prescriptionLogs.filter(o => {
          if (o.eventtype == 'Prescription Cancelled') {
            if (o.cancelledby != 'Stripe') {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        });
      }

      this.prescriptionLogs = this.prescriptionLogs.map(log => {
        if (log !== null && log !== void 0 && log.eventtype.includes('Prescription Paused until')) {
          var _log$prescription;

          if (log !== null && log !== void 0 && (_log$prescription = log.prescription) !== null && _log$prescription !== void 0 && _log$prescription.resumecollection) {
            var _log$prescription2;

            return { ...log,
              eventtype: `Prescription Paused until ${this.parseUnixDate(log === null || log === void 0 ? void 0 : (_log$prescription2 = log.prescription) === null || _log$prescription2 === void 0 ? void 0 : _log$prescription2.resumecollection, 'yyyy-MM-dd')}`
            };
          }
        }

        return log;
      });

      if (this.prescriptionLogs.length == 0) {
        this.alert.message = 'This prescription has no logs.';
        this.alert.show = true;
      }

      this.loading = false;
    }

  },
  methods: {
    // Button disablers
    disablePlay(prescription) {
      return !this.statusMapper[prescription.prescriptionstatus].controls.play;
    },

    disablePause(prescription) {
      return !this.statusMapper[prescription.prescriptionstatus].controls.pause;
    },

    disableStop(prescription) {
      return !this.statusMapper[prescription.prescriptionstatus].controls.stop;
    },

    formatDate(date, format) {
      if (!date) {
        return '-';
      } else {
        return moment(date).format(format);
      }
    },

    parseUnixDate(date, formatString) {
      return format(fromUnixTime(date), formatString);
    },

    openSingleCancel(prescription) {
      if (!this.statusMapper[prescription.prescriptionstatus].controls.stop) {
        return;
      } else {
        this.$emit('open-cancel-single', prescription);
      }
    },

    getRefillString(med) {
      if (med.refills) {
        if (['SCHEDULED', 'WAITING', 'PROCESSING', 'MIGRATED', 'DRAFTED'].includes(med.prescriptionstatus)) {
          return `Refills: ${med.refillinput}`;
        }

        const refills = med.refills - med.paidrefills;

        if (med.paidrefills === 0 && (med.paymentstatus === 'FAILED' || med.prescriptionstatus === 'PAUSED' || med.prescriptionstatus === 'CANCELLED')) {
          return `${med.refills - med.paidrefills - 1} out of ${med.refills - 1} refills`;
        }

        if (med.refills && med.paidrefills) {
          return `${refills} out of ${med.refills - 1} refills`;
        } else {
          return 'One time purchase';
        }
      } else {
        return 'No refills';
      }
    },

    openPrescriptionLog(prescription) {
      this.selectedPrescription = prescription;
    },

    formatLogTime(timestamp) {
      return moment(timestamp * 1000).format('MM/DD/YYYY LT');
    },

    getDoctorName(id) {
      const doctor = this.doctors.find(o => o.id == id);

      if (doctor !== null && doctor !== void 0 && doctor.firstname && doctor !== null && doctor !== void 0 && doctor.lastname) {
        return `Dr. ${doctor.firstname} ${doctor.lastname}`;
      }
    },

    getUserValue(row) {
      const doctorEvents = ['Prescription Updated', 'Prescription Re-Ordered'];

      if (row.eventtype == 'Prescription Created') {
        return this.getDoctorName(row.doctorid);
      } else if (row.eventtype == 'Prescription Cancelled' || row.eventtype == 'Prescription Canceled' || row.eventtype == 'Prescription Completed') {
        if (row.cancelledbyrole == 'ADMIN') {
          return row.cancelledby;
        } else if (row.cancelledbyrole == 'DOCTOR') {
          return `Dr. ${row.cancelledby}`;
        } else if (row.role == 'ADMIN') {
          return row.actionby;
        } else {
          if (!row.cancelledby) {
            if (row.actionby) {
              return row.actionby;
            }
          } else {
            return row.cancelledby;
          }
        }
      } else if (doctorEvents.includes(row.eventtype)) {
        return this.getDoctorName(row.doctorid);
      } else if (row.eventtype.startsWith('Prescription Scheduled')) {
        return this.getDoctorName(row.doctorid);
      } else if (row.eventtype.startsWith('Prescription Paused') && row.role == 'PATIENT') {
        return row.role;
      } else {
        if (row.role == 'DOCTOR') {
          return `Dr. ${row.actionby}`;
        } else {
          return row.actionby;
        }
      }
    },

    getCSSClasses(prescription) {
      const status = prescription.prescriptionstatus.replace(/\s+/g, '-').toLowerCase();
      const activestatus = this.selectedPrescription.id == prescription.id ? `active-${status}` : 'tw-border !tw-border-transparent';
      return [status, activestatus];
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

  }
};